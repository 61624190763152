export default {
  'actionbar.button.start.torrent': '开始 Torrent',
  'actionbar.button.stop.torrent': '停止 Torrent',
  'actionbar.button.add.torrent': '添加 Torrent',
  'actionbar.button.remove.torrent': '移除 Torrent',

  'alert.torrent.add': `成功添加 {countElement} {count, plural,
        =1 {torrent}
        other {torrents}
      }。`,
  'alert.torrent.add.failed': `添加 {countElement} {count, plural,
        =1 {torrent}
        other {torrents}
      } 失败。`,
  'alert.torrent.move': `移动 {countElement} {count, plural,
        =1 {torrent}
        other {torrents}
      } 成功。`,
  'alert.torrent.move.failed': `移动 {countElement} {count, plural,
        =1 {torrent}
        other {torrents}
      } 失败。`,
  'alert.torrent.remove': `成功移除 {countElement} {count, plural,
        =1 {torrent}
        other {torrents}
      }。`,
  'alert.torrent.remove.failed': `移除 {countElement} {count, plural,
        =1 {torrent}
        other {torrents}
      } 失败。`,
  'alert.settings.saved': '设置保存成功！',

  'auth.add.user': '增加用户',
  'auth.connectionType': 'rTorrent 连接类型',
  'auth.connectionType.tcp': 'TCP',
  'auth.connectionType.socket': 'Unix Socket',
  'auth.create.account': '创建账号',
  'auth.create.an.account': '创建一个账号',
  'auth.create.an.account.intro': 'Welcome to Flood!',
  'auth.current.user': '当前用户',
  'auth.error.username.empty': '用户名不能为空',
  'auth.log.in': 'Log In',
  'auth.login': 'Login',
  'auth.password': '密码',
  'auth.user.accounts': '用户账号',
  'auth.username': '用户名',
  'auth.admin': 'Admin',
  'auth.message.not.admin': '用户不是 Admin',
  'auth.rtorrentHost': 'rTorrent Host',
  'auth.rtorrentPort': 'rTorrent Port',
  'auth.rtorrentSocket': 'rTorrent Socket',
  'auth.rtorrentSocketPath': 'rTorrent Socket Path',

  'button.add': '新增',
  'button.cancel': '取消',
  'button.no': '否',
  'button.save': '保存设置',
  'button.save.feed': '保存',
  'button.test': '测试',
  'button.state.adding': '增加中...',
  'button.yes': '是',

  'connectivity.modal.title': 'Connectivity Issue',
  'connectivity.modal.content': '无法连接至 rTorrent. 请更新信息！',

  'feeds.add.automatic.download.rule': '增加下载规则',
  'feeds.add.feed': '添加Feed',
  'feeds.applicable.feed': '可用的 Feed',
  'feeds.apply.tags': '应用的 Tags',
  'feeds.exclude.pattern': '排除模式',
  'feeds.existing.feeds': '现有的 Feeds',
  'feeds.existing.rules': '现有的规则',
  'feeds.label': '标签',
  'feeds.match.count': '{count, plural, =1 {# match} other {# matches}}',
  'feeds.match.pattern': '匹配模式',
  'feeds.match': 'Match',
  'feeds.exclude': '排除',
  'feeds.no.feeds.available': '没有可用的 feeds。',
  'feeds.no.feeds.defined': '没有定义 feeds。',
  'feeds.no.rules.defined': '没有定义规则。',
  'feeds.regEx': '正则',
  'feeds.select.feed': '选择 Feed',
  'feeds.select.interval': '间隔',
  'feeds.start.on.load': '加载并启动',
  'feeds.tabs.download.rules': '下载规则',
  'feeds.tabs.feeds': 'Feeds',
  'feeds.tabs.heading': 'Torrent 源',
  'feeds.tags': '标签',
  'feeds.time.hr': '时',
  'feeds.time.min': '分',
  'feeds.time.day': '天',
  'feeds.torrent.destination': 'Torrent 目的地',
  'feeds.url': 'URL',
  'feeds.search': '搜索词',
  'feeds.validation.invalid.regular.expression': '无效的正则表达式。',
  'feeds.validation.must.select.feed': '你必须选择一个 feed。',
  'feeds.validation.must.specify.destination': '你必须指定目的地。',
  'feeds.validation.must.specify.label': '你必须指定标签。',
  'feeds.validation.must.specify.valid.feed.url': '你必须指定有效的feed网址。',

  'filesystem.empty.directory': '空目录.',
  'filesystem.error.eacces': 'Flood无权读取此目录。',
  'filesystem.error.enoent': '路径不存在，将会创建此目录。',
  'filesystem.fetching': '获取目录结构...',

  'filter.all': 'All',
  'filter.status.title': '根据状态过滤',
  'filter.status.downloading': '下载中',
  'filter.status.completed': '已完成',
  'filter.status.active': '活动中',
  'filter.status.inactive': '做种中',
  'filter.status.error': '错误',
  'filter.tracker.title': '根据Tracker过滤',
  'filter.tag.title': '根据Tag过滤',
  'filter.untagged': '未标记',

  'general.ago': 'ago',
  'general.at': 'at',
  'general.to': 'to',
  'general.of': 'of',

  'general.clipboard.copy': '复制',
  'general.clipboard.copied': '复制成功',

  'locale.language.en': '英语',
  'locale.language.fr': '法语',
  'locale.language.ko': '韩语',
  'locale.language.nl': '荷兰语',
  'locale.language.zh': '中文',

  'mediainfo.execError': '在服务器上运行mediainfo时发生错误。检查mediainfo是否已经安装，并且在PTAH中正确配置！',
  'mediainfo.fetching': '获取中...',
  'mediainfo.heading': 'Mediainfo 输出',

  'notification.torrent.finished.heading': '下载完成！',
  'notification.torrent.finished.body': '{name}',
  'notification.torrent.errored.heading': '错误报告',
  'notification.torrent.errored.body': '{name}',
  'notification.clear.all': '全部清除',
  'notification.showing': 'Showing',

  'priority.dont.download': '不下载',
  'priority.high': '高',
  'priority.low': '低',
  'priority.normal': '正常',

  'settings.bandwidth.slots.download.divider.label': '下载槽分配器',
  'settings.bandwidth.slots.download.global.label': '从全球下载槽',
  'settings.bandwidth.slots.download.label': '下载每个Torrent插槽',
  'settings.bandwidth.slots.heading': '槽可用性',
  'settings.bandwidth.slots.upload.divider.label': '上传槽分配器',
  'settings.bandwidth.slots.upload.global.label': '上传槽至全球',
  'settings.bandwidth.slots.upload.label': '上传每个Torrent的插槽',
  'settings.bandwidth.transferrate.dropdown.preset.download.label': '下拉预设：下载',
  'settings.bandwidth.transferrate.dropdown.preset.upload.label': '下拉预设：上传',
  'settings.bandwidth.transferrate.global.throttle.download': '全球下载比例阈值',
  'settings.bandwidth.transferrate.global.throttle.upload': '全球上传比例阈值',
  'settings.bandwidth.transferrate.heading': '传输比例阈值',

  'settings.connectivity.dht.label': '启动 DHT',
  'settings.connectivity.dht.port.label': 'DHT 端口',
  'settings.connectivity.dpd.heading': 'Decentralized Peer Discovery',
  'settings.connectivity.incoming.heading': '传入连接',
  'settings.connectivity.ip.hostname.label': '报告 IP/Hostname',
  'settings.connectivity.max.http.connections': '最大HTTP连接',
  'settings.connectivity.peer.exchange.label': '可以Peer交换',
  'settings.connectivity.peers.desired.label': 'Peers Desired',
  'settings.connectivity.peers.heading': 'Peers',
  'settings.connectivity.peers.max.label': '最大Peers值',
  'settings.connectivity.peers.min.label': '最小Peers值',
  'settings.connectivity.peers.seeding.max.label': '最大做种数',
  'settings.connectivity.peers.seeding.min.label': '最小做种数',
  'settings.connectivity.port.open.label': '打开端口',
  'settings.connectivity.port.randomize.label': '随机端口',
  'settings.connectivity.port.range.label': '端口范围',

  'settings.resources.disk.check.hash.label': '完成时验证Hash值',
  'settings.resources.disk.download.location.label': '默认下载目录',
  'settings.resources.disk.heading': '磁盘',
  'settings.resources.max.open.files': '打开文件最大值',
  'settings.resources.memory.heading': '内存',
  'settings.resources.memory.max.label': '最大内存使用',

  'settings.tabs.bandwidth': '带宽',
  'settings.tabs.connectivity': '连接',
  'settings.tabs.heading': '设置',
  'settings.tabs.resources': '资源',
  'settings.tabs.authentication': '认证',
  'settings.tabs.userinterface': '用户接口',
  'settings.tabs.about': '关于',

  'settings.ui.locale': '语言环境',
  'settings.ui.language': '语言',
  'settings.ui.torrent.list': 'Torrent 列表显示',
  'settings.ui.torrent.size': 'Torrent 尺寸',
  'settings.ui.torrent.size.expanded': '展开试图',
  'settings.ui.torrent.size.condensed': '凝聚试图',
  'settings.ui.torrent.details.enabled': '启动',
  'settings.ui.torrent.details.tags.placement': '在展开视图中，tags在列表末尾最有效。',

  'settings.about.flood': '关于Flood',

  'sidebar.button.feeds': '订阅',
  'sidebar.button.notifications': '通知',
  'sidebar.button.settings': '设置',
  'sidebar.button.speedlimits': '速度限制',
  'sidebar.button.log.out': '登出',

  'sidebar.search.placeholder': '搜索 torrents',

  'sidebar.transferdata.downloaded': '已下载',
  'sidebar.transferdata.uploaded': '已上传',

  'speed.unlimited': '不限制',

  'unit.size.byte': 'B',
  'unit.size.kilobyte': 'kB',
  'unit.size.megabyte': 'MB',
  'unit.size.gigabyte': 'GB',
  'unit.size.terabyte': 'TB',
  'unit.speed': '{baseUnit}/s',
  'unit.time.year': '年',
  'unit.time.week': '周',
  'unit.time.day': '天',
  'unit.time.hour': '时',
  'unit.time.minute': '分',
  'unit.time.second': '秒',
  'unit.time.infinity': '∞',

  'torrents.add.button.add': '添加Torrent',
  'torrents.add.destination.label': '路径',
  'torrents.add.destination.placeholder': '路径',
  'torrents.add.heading': '添加 Torrents',
  'torrents.add.start.label': '启动 Torrent',
  'torrents.add.tab.file.browse': '或通过浏览器打开',
  'torrents.add.tab.file.drop': '拖拽文件到这里,',
  'torrents.add.tab.file.title': '根据文件',
  'torrents.add.tab.url.input.placeholder': 'Torrent URL或者磁力链接',
  'torrents.add.tab.url.title': '根据URL',
  'torrents.add.torrents.label': 'Torrents',

  'torrents.destination.base_path': '作为基础路径',

  'torrents.details.actions.pause': '暂停',
  'torrents.details.actions.start': '开始',
  'torrents.details.actions.stop': '停止',
  'torrents.details.details': '详情',
  'torrents.details.files': '文件',
  'torrents.details.files.loading': '加载文件详情...',
  'torrents.details.files.download.file': `{count, plural,
      =1 {Download File}
      other {Download Files}
    }`,
  'torrents.details.general.comment': '评论',
  'torrents.details.general.connected': '{connected} connected of {total}',
  'torrents.details.general.date.added': '已添加',
  'torrents.details.general.date.created': '创建日期',
  'torrents.details.general.downloaded': '已下载',
  'torrents.details.general.free.disk.space': '可用磁盘空间',
  'torrents.details.general.hash': 'Hash',
  'torrents.details.general.heading.general': '概括',
  'torrents.details.general.heading.torrent': 'Torrent',
  'torrents.details.general.heading.tracker': 'Tracker',
  'torrents.details.general.heading.transfer': '传输',
  'torrents.details.general.location': '位置',
  'torrents.details.general.none': 'None',
  'torrents.details.general.peers': 'Peers',
  'torrents.details.general.scheduler.ignored': 'Ignored',
  'torrents.details.general.scheduler.obeyed': 'Obeyed',
  'torrents.details.general.scheduler': 'Scheduler',
  'torrents.details.general.seeds': '种子',
  'torrents.details.general.size': '尺寸',
  'torrents.details.general.tags': 'Tags',
  'torrents.details.general.tracker.message': 'Tracker 消息',
  'torrents.details.general.type.private': 'Private',
  'torrents.details.general.type.public': 'Public',
  'torrents.details.general.type': 'Type',
  'torrents.details.mediainfo': 'Mediainfo',
  'torrents.details.peers.no.data': '这个种子没有peer数据。',
  'torrents.details.peers': 'Peers',
  'torrents.details.selected.files': `{count, plural,
      =1 {{countElement} selected file}
      other {{countElement} selected files}
    }`,
  'torrents.details.selected.files.set.priority': '设置优先级',
  'torrents.details.trackers.no.data': '这个种子没有tracker 数据。',
  'torrents.details.trackers.type': 'Type',
  'torrents.details.trackers': 'Trackers',

  'torrents.list.clear.filters': '清除过滤器',
  'torrents.list.context.check.hash': '检查 Hash',
  'torrents.list.context.details': 'Torrent 详情',
  'torrents.list.context.move': '设置 Torrent 路径',
  'torrents.list.context.pause': '暂停',
  'torrents.list.context.priority': '优先级',
  'torrents.list.context.remove': '移除',
  'torrents.list.context.set.tags': '设置 Tags',
  'torrents.list.context.start': '开始',
  'torrents.list.context.stop': '停止',
  'torrents.list.no.torrents': '没有 torrents.',
  'torrents.list.drop': '移动文件以将他添加到 rTorrent.',
  'torrents.list.cannot.connect': '无法连接到 rTorrent.',
  'torrent.list.peers': '{connected} {of} {total}',
  'torrent.list.peers.of': 'of',

  'torrents.move.button.set.location': '设置下载路径',
  'torrents.move.button.state.setting': '设置中...',
  'torrents.move.data.label': '移动数据',
  'torrents.move.heading': '设置 Torrent 路径',

  'torrents.properties.date.added': '已添加',
  'torrents.properties.base.path': 'Base Path',
  'torrents.properties.comment': '备注',
  'torrents.properties.creation.date': '添加日期',
  'torrents.properties.download.speed': '现在速度',
  'torrents.properties.download.total': '已下载',
  'torrents.properties.eta': 'ETA',
  'torrents.properties.free.disk.space': '剩余硬盘空间',
  'torrents.properties.hash': 'Hash',
  'torrents.properties.ignore.schedule': '忽略调度程序',
  'torrents.properties.is.private': 'Private',
  'torrents.properties.name': '名称',
  'torrents.properties.percentage': '完成百分比',
  'torrents.properties.ratio': '分享率',
  'torrents.properties.size': '文件尺寸',
  'torrents.properties.tags': 'Tags',
  'torrents.properties.tracker.message': 'Tracker 信息',
  'torrents.properties.upload.speed': '上传速度',
  'torrents.properties.upload.total': '已上传',

  'torrents.remove.are.you.sure': `你确定移动 {count, plural,
        =0 {no torrents}
        =1 {one torrent}
        other {# torrents}
      }?`,
  'torrents.remove.delete.data': '删除数据',
  'torrents.remove.error.no.torrents.selected': '你没有选中任何 torrents.',
  'torrents.remove': '移除 Torrents',

  'torrents.set.tags.button.set': '设置 Tags',
  'torrents.set.tags.heading': '设置 Tags',
  'torrents.set.tags.enter.tags': '输入 tags',

  'torrents.sort.title': '排序',

  'connection-interruption.heading': '无法连接至 rTorrent',
  'connection-interruption.verify-settings-prompt': '让我们验证你的连接设置。',
  'connection-interruption.verification-error': '无法验证连接。',
  'connection-interruption.verification-success': '连接成功！',
};
