export default {
  'actionbar.button.start.torrent': 'Start Torrent',
  'actionbar.button.stop.torrent': 'Stop Torrent',
  'actionbar.button.add.torrent': 'Add Torrent',
  'actionbar.button.remove.torrent': 'Remove Torrent',

  'alert.torrent.add': `Successfully added {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    }.`,
  'alert.torrent.add.failed': `Failed to add {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    }.`,
  'alert.torrent.move': `Successfully moved {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    }.`,
  'alert.torrent.move.failed': `Failed to move {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    }.`,
  'alert.torrent.remove': `Successfully removed {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    }.`,
  'alert.torrent.remove.failed': `Failed to remove {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    }.`,
  'alert.settings.saved': 'Successfully saved settings.',

  'auth.add.user': 'Add User',
  'auth.connectionType': 'rTorrent Connection Type',
  'auth.connectionType.tcp': 'TCP',
  'auth.connectionType.socket': 'Unix Socket',
  'auth.create.account': 'Create Account',
  'auth.create.an.account': 'Create an account',
  'auth.create.an.account.intro': 'Welcome to Flood!',
  'auth.current.user': 'Current User',
  'auth.error.username.empty': 'Username cannot be empty.',
  'auth.log.in': 'Log In',
  'auth.login': 'Login',
  'auth.password': 'Password',
  'auth.user.accounts': 'User Accounts',
  'auth.username': 'Username',
  'auth.admin': 'Admin',
  'auth.message.not.admin': 'User is not Admin',
  'auth.rtorrentHost': 'rTorrent Host',
  'auth.rtorrentPort': 'rTorrent Port',
  'auth.rtorrentSocket': 'rTorrent Socket',
  'auth.rtorrentSocketPath': 'rTorrent Socket Path',

  'button.add': 'Add',
  'button.cancel': 'Cancel',
  'button.no': 'No',
  'button.save': 'Save Settings',
  'button.save.feed': 'Save',
  'button.test': 'Test',
  'button.state.adding': 'Adding...',
  'button.yes': 'Yes',

  'connectivity.modal.title': 'Connectivity Issue',
  'connectivity.modal.content': 'Cannot connect to rTorrent. Please update the information now.',

  'feeds.add.automatic.download.rule': 'Add Download Rule',
  'feeds.add.feed': 'Add Feed',
  'feeds.applicable.feed': 'Applicable Feed',
  'feeds.apply.tags': 'Apply Tags',
  'feeds.exclude.pattern': 'Exclude Pattern',
  'feeds.existing.feeds': 'Existing Feeds',
  'feeds.existing.rules': 'Existing Rules',
  'feeds.label': 'Label',
  'feeds.match.count': '{count, plural, =1 {# match} other {# matches}}',
  'feeds.match.pattern': 'Match Pattern',
  'feeds.match': 'Match',
  'feeds.exclude': 'Exclude',
  'feeds.no.feeds.available': 'No feeds available.',
  'feeds.no.feeds.defined': 'No feeds defined.',
  'feeds.no.rules.defined': 'No rules defined.',
  'feeds.regEx': 'RegEx',
  'feeds.select.feed': 'Select Feed',
  'feeds.select.interval': 'Interval',
  'feeds.start.on.load': 'Start on load',
  'feeds.tabs.download.rules': 'Download Rules',
  'feeds.tabs.feeds': 'Feeds',
  'feeds.tabs.heading': 'Torrent Feeds',
  'feeds.tags': 'Tags',
  'feeds.time.hr': 'Hours',
  'feeds.time.min': 'Minutes',
  'feeds.time.day': 'Days',
  'feeds.torrent.destination': 'Torrent Destination',
  'feeds.url': 'URL',
  'feeds.search': 'Search term',
  'feeds.validation.invalid.regular.expression': 'Invalid regular expression.',
  'feeds.validation.must.select.feed': 'You must select a feed.',
  'feeds.validation.must.specify.destination': 'You must specify a destination.',
  'feeds.validation.must.specify.label': 'You must specify a label.',
  'feeds.validation.must.specify.valid.feed.url': 'You must specify a valid feed URL.',

  'filesystem.empty.directory': 'Empty directory.',
  'filesystem.error.eacces': 'Flood does not have permission to read this directory.',
  'filesystem.error.enoent': 'This path does not exist. It will be created.',
  'filesystem.fetching': 'Fetching directory structure...',

  'filter.all': 'All',
  'filter.status.title': 'Filter by Status',
  'filter.status.downloading': 'Downloading',
  'filter.status.completed': 'Complete',
  'filter.status.active': 'Active',
  'filter.status.inactive': 'Inactive',
  'filter.status.error': 'Error',
  'filter.tracker.title': 'Filter by Tracker',
  'filter.tag.title': 'Filter by Tag',
  'filter.untagged': 'Untagged',

  'general.ago': 'ago',
  'general.at': 'at',
  'general.to': 'to',
  'general.of': 'of',

  'general.clipboard.copy': 'Copy',
  'general.clipboard.copied': 'Copied',

  'locale.language.en': 'English',
  'locale.language.fr': 'French',
  'locale.language.ko': 'Korean',
  'locale.language.nl': 'Nederlands',
  'locale.language.zh': 'Chinese',

  'mediainfo.execError':
    'An error occurred while running mediainfo on the server. Check that mediainfo is installed and available in the PATH to Flood.',
  'mediainfo.fetching': 'Fetching...',
  'mediainfo.heading': 'Mediainfo Output',

  'notification.torrent.finished.heading': 'Finished Downloading',
  'notification.torrent.finished.body': '{name}',
  'notification.torrent.errored.heading': 'Error Reported',
  'notification.torrent.errored.body': '{name}',
  'notification.clear.all': 'Clear All',
  'notification.showing': 'Showing',

  'priority.dont.download': "Don't Download",
  'priority.high': 'High',
  'priority.low': 'Low',
  'priority.normal': 'Normal',

  'settings.bandwidth.slots.download.divider.label': 'Download Slots Divider',
  'settings.bandwidth.slots.download.global.label': 'Download Slots Global',
  'settings.bandwidth.slots.download.label': 'Download Slots Per Torrent',
  'settings.bandwidth.slots.heading': 'Slot Availability',
  'settings.bandwidth.slots.upload.divider.label': 'Upload Slots Divider',
  'settings.bandwidth.slots.upload.global.label': 'Upload Slots Global',
  'settings.bandwidth.slots.upload.label': 'Upload Slots Per Torrent',
  'settings.bandwidth.transferrate.dropdown.preset.download.label': 'Dropdown Presets: Download',
  'settings.bandwidth.transferrate.dropdown.preset.upload.label': 'Dropdown Presets: Upload',
  'settings.bandwidth.transferrate.global.throttle.download': 'Global Download Rate Throttle',
  'settings.bandwidth.transferrate.global.throttle.upload': 'Global Upload Rate Throttle',
  'settings.bandwidth.transferrate.heading': 'Transfer Rate Throttles',

  'settings.connectivity.dht.label': 'Enable DHT',
  'settings.connectivity.dht.port.label': 'DHT Port',
  'settings.connectivity.dpd.heading': 'Decentralized Peer Discovery',
  'settings.connectivity.incoming.heading': 'Incoming Connections',
  'settings.connectivity.ip.hostname.label': 'Reported IP/Hostname',
  'settings.connectivity.max.http.connections': 'Maximum HTTP Connections',
  'settings.connectivity.peer.exchange.label': 'Enable Peer Exchange',
  'settings.connectivity.peers.desired.label': 'Peers Desired',
  'settings.connectivity.peers.heading': 'Peers',
  'settings.connectivity.peers.max.label': 'Maximum Peers',
  'settings.connectivity.peers.min.label': 'Minimum Peers',
  'settings.connectivity.peers.seeding.max.label': 'Maximum Peers Seeding',
  'settings.connectivity.peers.seeding.min.label': 'Minimum Peers Seeding',
  'settings.connectivity.port.open.label': 'Open Port',
  'settings.connectivity.port.randomize.label': 'Randomize Port',
  'settings.connectivity.port.range.label': 'Port Range',

  'settings.resources.disk.check.hash.label': 'Verify Hash on Completion',
  'settings.resources.disk.download.location.label': 'Default Download Directory',
  'settings.resources.disk.heading': 'Disk',
  'settings.resources.max.open.files': 'Maximum Open Files',
  'settings.resources.memory.heading': 'Memory',
  'settings.resources.memory.max.label': 'Max Memory Usage',

  'settings.tabs.bandwidth': 'Bandwidth',
  'settings.tabs.connectivity': 'Connectivity',
  'settings.tabs.heading': 'Settings',
  'settings.tabs.resources': 'Resources',
  'settings.tabs.authentication': 'Authentication',
  'settings.tabs.userinterface': 'User Interface',
  'settings.tabs.about': 'About',

  'settings.ui.locale': 'Locale',
  'settings.ui.language': 'Language',
  'settings.ui.torrent.list': 'Torrent List Display',
  'settings.ui.torrent.size': 'Torrent Size',
  'settings.ui.torrent.size.expanded': 'Expanded View',
  'settings.ui.torrent.size.condensed': 'Condensed View',
  'settings.ui.torrent.details.enabled': 'Enabled',
  'settings.ui.torrent.details.tags.placement': 'In the expanded view, tags work best at the end of the list.',

  'settings.about.flood': 'About Flood',

  'sidebar.button.feeds': 'Feeds',
  'sidebar.button.notifications': 'Notifications',
  'sidebar.button.settings': 'Settings',
  'sidebar.button.speedlimits': 'Speed Limits',
  'sidebar.button.log.out': 'Log Out',

  'sidebar.search.placeholder': 'Search torrents',

  'sidebar.transferdata.downloaded': 'Downloaded',
  'sidebar.transferdata.uploaded': 'Uploaded',

  'speed.unlimited': 'Unlimited',

  'unit.size.byte': 'B',
  'unit.size.kilobyte': 'kB',
  'unit.size.megabyte': 'MB',
  'unit.size.gigabyte': 'GB',
  'unit.size.terabyte': 'TB',
  'unit.speed': '{baseUnit}/s',
  'unit.time.year': 'yr',
  'unit.time.week': 'wk',
  'unit.time.day': 'd',
  'unit.time.hour': 'hr',
  'unit.time.minute': 'm',
  'unit.time.second': 's',
  'unit.time.infinity': '∞',

  'torrents.add.button.add': 'Add Torrent',
  'torrents.add.destination.label': 'Destination',
  'torrents.add.destination.placeholder': 'Destination',
  'torrents.add.heading': 'Add Torrents',
  'torrents.add.start.label': 'Start Torrent',
  'torrents.add.tab.file.browse': 'or click to browse',
  'torrents.add.tab.file.drop': 'Drop some files here,',
  'torrents.add.tab.file.title': 'By File',
  'torrents.add.tab.url.input.placeholder': 'Torrent URL or Magnet Link',
  'torrents.add.tab.url.title': 'By URL',
  'torrents.add.torrents.label': 'Torrents',

  'torrents.destination.base_path': 'Use as Base Path',

  'torrents.details.actions.pause': 'Pause',
  'torrents.details.actions.start': 'Start',
  'torrents.details.actions.stop': 'Stop',
  'torrents.details.details': 'Details',
  'torrents.details.files': 'Files',
  'torrents.details.files.loading': 'Loading file detail...',
  'torrents.details.files.download.file': `{count, plural,
    =1 {Download File}
    other {Download Files}
  }`,
  'torrents.details.general.comment': 'Comment',
  'torrents.details.general.connected': '{connected} connected of {total}',
  'torrents.details.general.date.added': 'Added',
  'torrents.details.general.date.created': 'Creation Date',
  'torrents.details.general.downloaded': 'Downloaded',
  'torrents.details.general.free.disk.space': 'Free Disk Space',
  'torrents.details.general.hash': 'Hash',
  'torrents.details.general.heading.general': 'General',
  'torrents.details.general.heading.torrent': 'Torrent',
  'torrents.details.general.heading.tracker': 'Tracker',
  'torrents.details.general.heading.transfer': 'Transfer',
  'torrents.details.general.location': 'Location',
  'torrents.details.general.none': 'None',
  'torrents.details.general.peers': 'Peers',
  'torrents.details.general.scheduler.ignored': 'Ignored',
  'torrents.details.general.scheduler.obeyed': 'Obeyed',
  'torrents.details.general.scheduler': 'Scheduler',
  'torrents.details.general.seeds': 'Seeds',
  'torrents.details.general.size': 'Size',
  'torrents.details.general.tags': 'Tags',
  'torrents.details.general.tracker.message': 'Tracker Message',
  'torrents.details.general.type.private': 'Private',
  'torrents.details.general.type.public': 'Public',
  'torrents.details.general.type': 'Type',
  'torrents.details.mediainfo': 'Mediainfo',
  'torrents.details.peers.no.data': 'There is no peer data for this torrent.',
  'torrents.details.peers': 'Peers',
  'torrents.details.selected.files': `{count, plural,
    =1 {{countElement} selected file}
    other {{countElement} selected files}
  }`,
  'torrents.details.selected.files.set.priority': 'Set Priority',
  'torrents.details.trackers.no.data': 'There is no tracker data for this torrent.',
  'torrents.details.trackers.type': 'Type',
  'torrents.details.trackers': 'Trackers',

  'torrents.list.clear.filters': 'Clear Filters',
  'torrents.list.context.check.hash': 'Check Hash',
  'torrents.list.context.details': 'Torrent Details',
  'torrents.list.context.move': 'Set Torrent Location',
  'torrents.list.context.pause': 'Pause',
  'torrents.list.context.priority': 'Priority',
  'torrents.list.context.remove': 'Remove',
  'torrents.list.context.set.tags': 'Set Tags',
  'torrents.list.context.start': 'Start',
  'torrents.list.context.stop': 'Stop',
  'torrents.list.no.torrents': 'No torrents to display.',
  'torrents.list.drop': 'Drop files here to add them to rTorrent.',
  'torrents.list.cannot.connect': 'Cannot connect to rTorrent.',
  'torrent.list.peers': '{connected} {of} {total}',
  'torrent.list.peers.of': 'of',

  'torrents.move.button.set.location': 'Set Location',
  'torrents.move.button.state.setting': 'Setting...',
  'torrents.move.data.label': 'Move data',
  'torrents.move.heading': 'Set Torrent Location',

  'torrents.properties.date.added': 'Added',
  'torrents.properties.base.path': 'Base Path',
  'torrents.properties.comment': 'Comment',
  'torrents.properties.creation.date': 'Creation Date',
  'torrents.properties.download.speed': 'Download Speed',
  'torrents.properties.download.total': 'Downloaded',
  'torrents.properties.eta': 'ETA',
  'torrents.properties.free.disk.space': 'Free Disk Space',
  'torrents.properties.hash': 'Hash',
  'torrents.properties.ignore.schedule': 'Ignore Scheduler',
  'torrents.properties.is.private': 'Private',
  'torrents.properties.name': 'Name',
  'torrents.properties.percentage': 'Percent Complete',
  'torrents.properties.ratio': 'Ratio',
  'torrents.properties.size': 'File Size',
  'torrents.properties.tags': 'Tags',
  'torrents.properties.tracker.message': 'Tracker Message',
  'torrents.properties.upload.speed': 'Upload Speed',
  'torrents.properties.upload.total': 'Uploaded',

  'torrents.remove.are.you.sure': `Are you sure you want to remove {count, plural,
      =0 {no torrents}
      =1 {one torrent}
      other {# torrents}
    }?`,
  'torrents.remove.delete.data': 'Delete data',
  'torrents.remove.error.no.torrents.selected': "You haven't selected any torrents.",
  'torrents.remove': 'Remove Torrents',

  'torrents.set.tags.button.set': 'Set Tags',
  'torrents.set.tags.heading': 'Set Tags',
  'torrents.set.tags.enter.tags': 'Enter tags',

  'torrents.sort.title': 'Sort By',

  'connection-interruption.heading': 'Cannot connect to rTorrent',
  'connection-interruption.verify-settings-prompt': "Let's verify your connection settings.",
  'connection-interruption.verification-error': 'Connection could not be verified.',
  'connection-interruption.verification-success': 'Connection successful',
};
